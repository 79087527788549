enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  GET_USER = "getUser",
  PATCH_USER = "patchUser",
  GET_DEPOSITS = "getDeposits",
  GET_DEPOSITS_TYPES = "getDepositsTypes",
  GET_DEPOSITS_STATUSES = "getDepositsStatuses",
  POST_DEPOSITS = "postDeposits",
  POST_VERIFICATION = "postVerification",
  POST_ONBOARDING = "postOnBoarding",
  GET_DEPOSITLIST = "getUserDepositList",
  GET_WALLETLIST = "getUserWalletList",
  GET_DOCUMENTLIST = "getDocumentList",
  GET_USER_DSAFE_WALLET_INFO = "getUserDsafeWalletInfo",
  GET_DSAFE_BENEFICE_WALLET_INFO = "getDsafeBenefitWalletInfo",
  GET_DSACTIVITYLIST = "getDSafeActivityList",
  GET_RECOMMANDEELIST ="getDSafeUserRecommandee", /* get the list of recommandee for the connecter user */
  GET_VAULT_EXPOSURE_SUMMARY ="getVaultExposure", /* get the exposure token BNB,ETH, USDT for the vault, it givess an indication to market exposure */
  GET_VAULT_NETWORTH_EVO_TOTAL = "getVaultNetworthEvoTotal",
  GET_USER_NETWORTH_EVO_DYNAMIC = "getVaultNetworthEvoUserDynamic",
  GET_USER_NETWORTH_EVO_BALANCED = "getVaultNetworthEvoUserBalanced",
  GET_VAULT_BENEFICE_EVO_USER_DYNAMIC = "getVaultBeneficEvoUserDynamic",
  GET_VAULT_BENEFICE_EVO_USER_BALANCED = "getVaultBeneficEvoUserBalanced",
  RESET_VAULT_BENEFICE_EVO_USER = "resetVaultBeneficEvoUser",
  GET_VAULT_MONTHY_SALARY_EVO_USER = "getVaultMonthlySalaryEvoUser",
  GET_VAULT_MONTHY_POTENTIAL_SALARY_EVO_USER = "getVaultMonthlyPotentialSalaryEvoUser",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
  GET_USER_NETWORTH_VAULT = "getUserNetWorthVault",
  GET_USER_NETWORTH_GLOBAL = "getUserNetWorthGlobal",
  GET_USER_BENEFICE_GLOBAL = "getBeneficeGlobal",
  GET_USER_VAULT_INVESTS = "getUserVaultInvests", /* get the list of invest with all yield and perfomance detail */
  GET_USER_INVEST_PROFIL = "getUserInvestProfil", /* get the % per vault for the connected user */
  GET_USER_VAULT_INVESTS_SUMMARY = "getUserVaultInvestsSummary", /* get the summary of GET_USER_VAULT_INVESTS */
  GET_USER_VAULT_INVESTS_SUMMARY_ALL_TIME = "getUserVaultInvestsSummaryAllTime", /* get the summary of GET_USER_VAULT_INVESTS */
  GET_VAULT_EXPO = "getVaultExpo",
  GET_USER_YIELD = "getUserYield", /* get the yield of the connected user */
  GET_REFERER_INCOMES = "getRefererIncomes", /* get the income of the connected user */
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_DEPOSITS = "setDeposits",
  SET_DEPOSITS_TYPES = "setDepositsTypes",
  SET_DEPOSITS_STATUSES = "setDepositsStatuses",
  SET_DEPOSITLIST = "setUserDepositList",
  SET_WALLETLIST = "setUserWalletList",
  SET_DOCUMENTLIST = "setDocumentList",
  SET_USER_DSAFE_WALLET_INFO = "setUserDsafeWalletInfo",
  SET_DSAFE_BENEFICE_WALLET_INFO = "setDsafeBenefitWalletInfo",
  SET_DSACTIVITYLIST = "setDSafeActivityList",
  SET_RECOMMANDEELIST = "setDSafeUserRecommandee",
  SET_VAULT_EXPOSURE_SUMMARY ="setVaultExposure",
  SET_VAULT_NETWORTH_EVO_TOTAL = "setVaultNetworthEvoTotal",
  SET_VAULT_MONTHY_SALARY_EVO_USER = "setVaultMonthlySalaryEvoUser",
  SET_VAULT_MONTHY_POTENTIAL_SALARY_EVO_USER = "setVaultMonthlyPotentialSalaryEvoUser",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  SET_USER_NETWORTH_VAULT = "setUserNetWorthVault",
  SET_VAULT_BENEFICE_EVO_USER_DYNAMIC ="setVaultBeneficEvoUserDynamic",
  SET_VAULT_BENEFICE_EVO_USER_BALANCED ="setVaultBeneficEvoUserBalanced",
  SET_USER_NETWORTH_EVO_DYNAMIC="setVaultNetworthEvoUserDynamic",
  SET_USER_NETWORTH_EVO_BALANCED="setVaultNetworthEvoUserBalanced",
  SET_USER_NETWORTH_GLOBAL = "setUserNetWorthGlobal",
  SET_USER_BENEFICE_GLOBAL = "setUserBeneficeGlobal",
  SET_USER_VAULT_INVESTS = "setUserVaultInvests",
  SET_USER_INVEST_PROFIL = "setUserInvestProfil",
  SET_USER_VAULT_INVESTS_SUMMARY = "setUserVaultInvestsSummary",
  SET_USER_VAULT_INVESTS_SUMMARY_ALL_TIME = "setUserVaultInvestsSummaryAllTime",
  SET_VAULT_EXPO = "setVaultExpo",
  SET_USER_YIELD = "setUserYield", /* set the yield of the connected user */
  SET_REFERER_INCOMES = "setRefererIncomes", /* set the income of the connected user */

}

export { Actions, Mutations };
