import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Wallet {
  wallet_address: string;
  client_account: string;
}

export interface WalletInfo {
  errors: unknown;
  wallets: Wallet;
}

@Module
export default class WalletModule extends VuexModule implements WalletInfo {
  errors = {};
  wallets = {} as Wallet;

  /**
   * Get current Wallet object
   * @returns WalletInfo
   */
  get currentUserWalletList(): Wallet {
    return this.wallets;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getWalletErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_WALLETLIST](data) {
    this.wallets = data;
    this.errors = {};
  }

  @Action
  [Actions.GET_WALLETLIST]() {
    ApiService.setHeader();
    ApiService.get("/get-wallet-list")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_WALLETLIST, data);
      })
      .catch(({ response }) => {
        //alert('error get wallet list API is broken');
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}

