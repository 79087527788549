import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface UserNetWorthVault {
    "id": number;
    "name": string;
}

export interface UserNetWorthVaultSet {
    errors: unknown;
    userNetWorthVault: UserNetWorthVault;
}

@Module
export class NetWorthEvolutionUserDynamic extends VuexModule implements UserNetWorthVaultSet {
    errors = {};
    userNetWorthVault = {} as UserNetWorthVault;

    get getUserWorthVaultDynamic(): UserNetWorthVault {
        return this.userNetWorthVault;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getNetWorthVaultErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_USER_NETWORTH_EVO_DYNAMIC](data) {
        this.userNetWorthVault = data;
        this.errors = {};
    }

    @Action
    [Actions.GET_USER_NETWORTH_EVO_DYNAMIC](id) {
        ApiService.setHeader();
        ApiService.get("/get-vault-networth-evo-user", id)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_USER_NETWORTH_EVO_DYNAMIC, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data);
            });
    }
}

@Module
export class NetWorthEvolutionUserBalanced extends VuexModule implements UserNetWorthVaultSet {
    errors = {};
    userNetWorthVault = {} as UserNetWorthVault;

    get getUserWorthVaultBalanced(): UserNetWorthVault {
        return this.userNetWorthVault;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getNetWorthVaultErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_USER_NETWORTH_EVO_BALANCED](data) {
        this.userNetWorthVault = data;
        this.errors = {};
    }

    @Action
    [Actions.GET_USER_NETWORTH_EVO_BALANCED](id) {
        ApiService.setHeader();
        ApiService.get("/get-vault-networth-evo-user", id)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_USER_NETWORTH_EVO_BALANCED, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data);
            });
    }
}

