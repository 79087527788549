import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface UserVaultInvests {
    value: string;
    create_datetime: string;
    gas: string;
    gasPrice: string;
}

export interface UserVaultInvestsSet {
    errors: unknown;
    userVaultInvests: UserVaultInvests;
}

@Module
export default class UserVaultInvestsModule extends VuexModule implements UserVaultInvestsSet {
    errors = {};
    userVaultInvests = {} as UserVaultInvests;

    /**
     * Get current user objecte
     * @returns UserVaultInvests
     */
    get getVaultInvests(): UserVaultInvests {
        return this.userVaultInvests;
    }


    /**
     * Get authentification errors
     * @returns array
     */
    get getVaultInvestsErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_USER_VAULT_INVESTS](data) {
        this.userVaultInvests = data;
        this.errors = {};
    }

    @Action
    [Actions.GET_USER_VAULT_INVESTS](id) {
        ApiService.setHeader();
        ApiService.get("/get-user-invests", id)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_USER_VAULT_INVESTS, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data);
            });
    }

}

