import { createI18n } from "vue-i18n";

const messages = {
    en: {
        action: {
            save: "Save",
            saveChanges: "Save Changes",
            back: "Back",
            submit: "Submit",
            continue: "Continue",
            discard: "Discard",
            getSupport: "Get Support",
            pleaseWait: "Please wait...",
            increaseByDeposit: "Increase by making a deposit",
            addPayment: "Add Deposit",
            mtpelerinverification: "Bank verification",
            editProfile: "Edit Profile",
            returnHome: "Return Home"
        },
        boolean: {},
        themeMode: {
            light: "Light",
            dark: "Dark",
            system: "System",
        },
        page: {
            dashboard: "Dashboard",
            sally: "Dynamic",
            salomon: "Balanced",
            silver: "Silver",
            depot: "DEPOSIT/WITHDRAW",
            documents: "Documents",

            account: "My Account",
            accountInfo: "Info",
            accountSettings: "Settings",
            signOut: "Sign Out",
            signIn: "Sign in",
        },
        section: {
            verification: {
                title: "Account Validation",
                kyc:"Your account is pending KYC validation.<br /><br/> If you already finished your KYC. <br />Please be patient, our team is reviewing your account for activation.",
                pending: "Your account is pending validation. You will receive an email as soon as we are ready to process your onboarding<br/> If you have any questions",
                refused: "Account validation is refused, please contact an administrator at",
            },
            mtPelerinVerification:{
                title: "Bank verification",
                importantAreaStablity: "Stability",
                importantAreaQuickFinancialHit: "Passive income",
                importantAreaPassiveIncome: "Make a Quick Financial Hit",
                importantAreaTranquility: "Tranquility",
                importantAreaCuriosity: "Curiosity",
                importantAreaDiversification: "Diversification",
                relevantProductStability: "The stability of my portfolio over time (life insurance type)",
                relevantProductMoneyManager: "Becoming a money changer",
                relevantProductExposeNewProject: "Exposure to new projects",
                relevantProductIAcontrolledWallet: "The fact that my wallet is controlled by an AI",
                relevantProductInvestReturn: "The fact that I can credit my assets with a return in the form of interest",
                relevantProductNFT: "The Nft's",
                relevantProductInvolvement: "My involvement in the market over time",
                message: "Message for the team ?",
                step1a: {
                    sectionTitle: "Your knowledge",
                    title: "Rate you knowledge on crypto and Defi market",
                    sectionDescription: "What do you know ?",
                    tooltip: "Select one",
                    one: {
                        title: "A little",
                    },
                    five: {
                        title: "A lot",
                    },
                },
                step1b: {
                    sectionTitle: "Your knowledge",
                    title: "On what scale are you willing to lose your capital?",
                    sectionDescription: "",
                    tooltip: "Select one",
                    one: {
                        title: "Not really",
                    },
                    five: {
                        title: "Yes",
                    },
                },
                step2: {
                    importantAreaTitle: "How important are these areas to you?",
                    relevantProductTitle: "What do you think would be the most relevant products?",
                    sectionTitle: "Risk",
                    sectionDescription: "What do you know ?",
                    q1:"Stability",
                    q2:"Passive Income",
                    tooltip: "Select one",
                    one: {
                        title: "A little",
                    },
                    five: {
                        title: "A lot",
                    },
                },
                step3: {
                    title: "Message",
                    sectionTitle: "Message",
                    description: "",
                },
                step4: {
                    sectionTitle: "Confirmation",
                    title: "Confirmation",
                    description: "",
                },
            },
            onboarding:{
                title: "Update my profile",
                importantAreaStablity: "Stability",
                importantAreaQuickFinancialHit: "Passive income",
                importantAreaPassiveIncome: "Make a Quick Financial Hit",
                importantAreaTranquility: "Tranquility",
                importantAreaCuriosity: "Curiosity",
                importantAreaDiversification: "Diversification",
                relevantProductStability: "The stability of my portfolio over time (life insurance type)",
                relevantProductMoneyManager: "Becoming a money changer",
                relevantProductExposeNewProject: "Exposure to new projects",
                relevantProductIAcontrolledWallet: "The fact that my wallet is controlled by an AI",
                relevantProductInvestReturn: "The fact that I can credit my assets with a return in the form of interest",
                relevantProductNFT: "The Nft's",
                relevantProductInvolvement: "My involvement in the market over time",
                message: "Message for the team ?",
                step1a: {
                    sectionTitle: "Your knowledge",
                    title: "Rate you knowledge on crypto and Defi market",
                    sectionDescription: "What do you know ?",
                    tooltip: "Select one",
                    one: {
                        title: "A little",
                    },
                    five: {
                        title: "A lot",
                    },
                },
                step1b: {
                    sectionTitle: "Your knowledge",
                    title: "On what scale are you willing to lose your capital?",
                    sectionDescription: "",
                    tooltip: "Select one",
                    one: {
                        title: "Not really",
                    },
                    five: {
                        title: "Yes",
                    },
                },
                step2: {
                    importantAreaTitle: "How important are these areas to you?",
                    relevantProductTitle: "What do you think would be the most relevant products?",
                    sectionTitle: "Risk",
                    sectionDescription: "What do you know ?",
                    q1:"Stability",
                    q2:"Passive Income",
                    tooltip: "Select one",
                    one: {
                        title: "A little",
                    },
                    five: {
                        title: "A lot",
                    },
                },
                step3: {
                    title: "Message",
                    sectionTitle: "Message",
                    description: "",
                },
                step4: {
                    sectionTitle: "Confirmation",
                    title: "Confirmation",
                    description: "",
                },
            },
            help: {
              label: "Help",
              title: "Learn & Get Inspired",
                support: {
                    title: "Get Support",
                    description: "Join our developers community to find answer to your question\n" +
                        "and help others.",
                },
                docAndVideo: {
                    title: "Documentation & Videos",
                    description: "From guides and video tutorials, to live demos and code\n" +
                        "                  examples to get started.",
                },
            },
            marketValue: {
                total: {
                    title: "All Vault Market Value",
                    description: "",
                },
                marketValue: {
                    title: "Market Value",
                    description: "Converted in $. How much your market value evolved in time.",
                },
            },
            marketBenefice: {
                total: {
                    title: "All vault Total Market Benefice",
                    description: "@",
                },
                marketBenefice: {
                    title: "Market Benefice",
                    description: "Converted in $. How much your market benefit value evolved in time.",
                },
            },
            bitcoinWidget: {
                title: "USD / BITCOIN value 30 days",
                description: "",
            },
            userVaultInvests: {
                title: "Arbitrage",
                description: "",
                mgmt:"Management Fee",
                referalBonus:"Referal Bonus",
                withdrawDescription1: "A withdraw was made",
                withdrawDescription2: "Value in USD at the time of withdraw :",
                depositDescription1: "A deposit was made",
                depositDescription2: "Value in USD at the time of deposit :",
            },
            depositList: {
                title: "Deposit",
                description: "This is a list of all your deposit.<br>\n" +
                    "          If you don't see a deposit you made, no panic, contact us.<br>\n" +
                    " ",
                depositDescription: "You made a deposit of",

            },
            vaultPie: {
                title: "Total amount (Investement+benefit)",
                userToken: "Investissement",
                userNewTokenAcquiredToken: "New acquired token Won with BP",
            },
            documentList: {
                title: "Documents",
                description: "Download your documents",
            },
            userVault: {
                title: "You hold",
                description: "Your % changes everyday",
                note: "Current sprint requires stakeholders<br />\n" +
                    "          to approve newly amended rules",
            },
            depositTable: {
                title: "Deposit records",
            },
            verificationModal: {
                title: "Bank Verification",
                step1: {
                    title: "Type of transfer",
                    description: "Bank or Crypto",
                    tooltip: "Select one type of transfer",
                    bank: {
                        title: "Bank Wire transfer or Credit Card",
                        description: "Transfert with bank wire transfert or user your credit card text",
                    },
                    crypto: {
                        title: "Crypto transfer",
                        description: "Crypto transfer",
                    },
                },
                step2: {
                    title: "Agreement",
                    description: "Enter the deposit amount",
                    tooltip: "Confirm your agreement with the rules",
                    agree: "I agree with all terms",
                    bankAgreements: {
                        1: {
                            title: "Deposit Policy",
                            text: "While making a deposit, it is always recommended to use a bank account in your name or your company's name. " +
                                "<br>However, for deposits less than €1000 within a 24-hour period, the bank account does not need to be under "+
                                "<br>the same name as the one registered on BlockPilot. " +
                                "<br>For deposits exceeding €1000 within 24 hours, it is mandatory to use a bank account under the same name as the one registered on BlockPilot." +
                                "<br><br>We accept <span style=\"color:#FFF\">SEPA</span> and <span style=\"color:#FFF\">SWIFT</span> transfers in partnership with our collaborator, Mt Pelerin, based in Switzerland." +
                                "<br><br>The accepted currencies for transfers are as follows :" +
                                "<br>- Swiss Franc (CHF) 🇨🇭 "+
                                "<br>- Euro (EUR) 🇪🇺 "+
                                "<br>- U.S. Dollar (USD) 🇺🇸 "+
                                "<br>- British Pound (GBP) 🇬🇧 "+
                                "<br>- Australian Dollar (AUD) 🇦🇺 "+
                                "<br>- Canadian Dollar (CAD) 🇨🇦 "+
                                "<br>- Danish Krone (DKK) 🇩🇰 "+
                                "<br>- Hong Kong Dollar (HKD) 🇭🇰 "+
                                "<br>- Japanese Yen (JPY) 🇯🇵"+
                                "<br>- Norwegian Krone (NOK) 🇳🇴"+
                                "<br>- New Zealand Dollar (NZD) 🇳🇿"+
                                "<br>- Swedish Krona (SEK) 🇸🇪"+
                                "<br>- Singapore Dollar (SGD) 🇸🇬"+
                                "<br><br> We also accept deposits via <span style=\"color:#FFF\">credit card</span>. " +
                                "<br>To use this payment method, it is necessary to register through the Mt Pelerin widget." +
                                "If you have any questions or need assistance, do not hesitate to contact our team.",
                        },
                        2: {
                            title: "Fees",
                            text: "At BlockPilot, we offer free transfers for each deposit made within a 24-hour period that does not exceed €500." +
                                "<br> No fees will be applied for these deposits."+
                                "For deposits exceeding €500 within a 24-hour period, our partner Mt Pelerin applies the following fees according to their pricing grid:"+
                                "<br>From €500 to €4,999: 1.3%" +
                                "<br>From €5,000 to €49,999: 1.1%" +
                                "<br>From €50,000 to €100,000: 0.9%" +
                                "<br><br>Please note that your bank and its intermediaries may also apply fees for international payments.",
                        },
                        3: {
                            title: "Delay",
                            text: "Please note that if it is your first deposit via our partner Mt Pelerin, it will take approximately 2 hours to complete " +
                                "the Mt Pelerin/BlockPilot account verification. " +
                                "<br>Once this verification is completed, please allow <span style=\"color:#FFF\">7 business days</span> for the funds to be available in your BlockPilot account." +
                                "<br>For subsequent deposits, funds may take <span style=\"color:#FFF\">1 to 5 business days</span> to appear in your BlockPilot account.",
                        },
                        4: {
                            title: "Questions",
                            text: "If you have any questions about the transfer or encounter any issues during your deposit, " +
                                "<br>please do not hesitate to contact our customer service." +
                                "<br>We are here to assist you.",
                        },
                    },
                    cryptoAgreements: {
                        1: {
                            title: "Crypto Policy",
                            text: "The minimum amount for a deposit is equivalent to $200,\n" +
                                "while the maximum amount is $50,000.<br> \n" +
                                "\n" +
                                "For any transaction exceeding this amount, please contact us directly. \n"+
                                " <br><br> \n" +
                                "The blockchain address to use is <span style=\"color:#FFF\">0xA33335CC3353516b31aED3edC171C3Bb1ceD6E12.</span>\n"+
                                "<br><br>Additionally, we now support ENS addresses, ours being blockp.bnb.\n" +
                                "<br><br>Please note that the Block Pilot protocol currently only accepts currencies from the BEP20 network. \n " +
                                "If you use the wrong network when making your blockchain transfer, \n" +
                                "your funds may not necessarily be lost, but it could result in additional fees.\n" +
                                "For a precise description of the process, do not hesitate to consult our documentation. \n"
                            ,
                        },
                        2: {
                            title: "Fees",
                            text:  "Please note that BlockPilot is not responsible for any potential transfer fees " +
                                "that may be charged by your blockchain service provider during" +
                                " the transfer operation. These fees are typically determined by the network itself.",
                        },
                        3: {
                            title: "Delay",
                            text: "After the transfer, please note that funds may take between <span style=\"color:#FFF\">1 to 5 business days</span> to be " +
                                "credited to your account due to the processing times of the blockchain and our internal systems.",
                        },
                        4: {
                            title: "Questions",
                            text: "If you have any questions about the transfer or encounter any issues during your deposit, " +
                                "<br>please do not hesitate to contact our customer service." +
                                "<br>We are here to assist you.",
                        },
                    },
                },
                step3: {
                    title: "Depot amount",
                    description: "Read user agreement",
                    amount: {
                        label: "BNB verification amount",
                        tooltip: "Please, go through the STEPS to find the correct amount in bellow MT Pelerin module.",
                    },
                },
                step4: {
                    title: "Bank Verification",
                    description: "Confirmation of depot",
                    bankTransfer: "Bank transfer",
                    cryptoTransfer: "Crypto transfer",
                    notice : "",
                    mtpelerin:"<span style=\"color:#FFF;font-weight:bold\">STEP 1: Account Verification</span>" +
                        "<br>For a secure experience, you need to create our Swiss partner MT Pelerin account."+
                        "<br><br>Clicking on the top right profil icon, will bring you to a form to provide your phone number. " +
                        "This will allow MT Pelerin to send you a confirmation SMS.<br><br>" +
                        "Once the account created and linked to your number, you will be able to make deposits faster." +
                        "<br> Numbers should be inputed without the (0) " +
                        "<br><br><span style=\"color:#FFF000\">Important Information:</span> The Mt Pelerin account is yours.<br>" +
                        "You will be in full control of your deposits, we do not have any control on the account." +
                        "This process is to register BLOCKPILOT address as a destination address only. <br>" +
                        "Once registered you will be able to Buy Crypto asset and send it to any adddress, BLOCKPILOT or others." +
                        "<br><br><span style=\"color:#FFF;font-weight:bold\">STEP 2: Amount and Currency</span> "+
                        "<br>Select random amount and currency. You can leave the method with bank transfert. <br>" +
                        "Don't worry there will be no transactions for the verification phase." +
                        "<br> <br> <span style=\"color:#FFF;font-weight:bold\">STEP 3: Register our Deposit Wallet</span>  " +
                        "<br>Now it's the time to add BLOCKPILOT address to be able use our vaults.<br>" +
                        "Click on ADD MANUALLY and Enter the Destination Address:<br> " +
                        "<span style=\"color:#FFF\">0xA33335CC3353516b31aED3edC171C3Bb1ceD6E12 </span>" +
                        "<br><br><span style=\"color:#FFF\">STEP 4: Copy/paste Informations:</span><br>" +
                        "To verify your account, we need the amount given by MT Pelering and the (to) verification address. " +
                        "<br>Please Copy the amount ie. 0.0000006878 BNB and the (to) address and fill the fields below.<br> " +
                        "<br>Warning: Without it, we can NOT register our deposit wallet in your account. <br>" +
                        "Click on submit when ready." +
                        "<br><br>The amount will transfered by BLOCKPILOT." +
                        "<br><br><span style=\"color:#FFF;font-weight:bold\">STEP 5: Email Confirmation</span>" +
                        "<br>Once you have received the confirmation email, forward the email to support." +
                        "<br><br>" +
                        "",
                    txs:"(to) Mt Pelerin BNB Chain address",
                },
            },
            depositModal: {
                title: "New depot",
                step1: {
                    title: "Type of transfer",
                    description: "Bank or Crypto",
                    tooltip: "Select one type of transfer",
                    bank: {
                        title: "Bank Wire transfer or Credit Card",
                        description: "Transfert with bank wire transfert or user your credit card text",
                    },
                    crypto: {
                        title: "Crypto transfer",
                        description: "Crypto transfer",
                    },
                },
                step2: {
                    title: "Agreement",
                    description: "Enter the deposit amount",
                    tooltip: "Confirm your agreement with the rules",
                    agree: "I agree with all terms",
                    bankAgreements: {
                        1: {
                            title: "Deposit Policy",
                            text: "While making a deposit, it is always recommended to use a bank account in your name or your company's name. " +
                                "<br>However, for deposits less than €1000 within a 24-hour period, the bank account does not need to be under "+
                                "<br>the same name as the one registered on BlockPilot. " +
                                "<br>For deposits exceeding €1000 within 24 hours, it is mandatory to use a bank account under the same name as the one registered on BlockPilot." +
                                "<br><br>We accept <span style=\"color:#FFF\">SEPA</span> and <span style=\"color:#FFF\">SWIFT</span> transfers in partnership with our collaborator, Mt Pelerin, based in Switzerland." +
                                "<br><br>The accepted currencies for transfers are as follows :" +
                                "<br>- Swiss Franc (CHF) 🇨🇭 "+
                                "<br>- Euro (EUR) 🇪🇺 "+
                                "<br>- U.S. Dollar (USD) 🇺🇸 "+
                                "<br>- British Pound (GBP) 🇬🇧 "+
                                "<br>- Australian Dollar (AUD) 🇦🇺 "+
                                "<br>- Canadian Dollar (CAD) 🇨🇦 "+
                                "<br>- Danish Krone (DKK) 🇩🇰 "+
                                "<br>- Hong Kong Dollar (HKD) 🇭🇰 "+
                                "<br>- Japanese Yen (JPY) 🇯🇵"+
                                "<br>- Norwegian Krone (NOK) 🇳🇴"+
                                "<br>- New Zealand Dollar (NZD) 🇳🇿"+
                                "<br>- Swedish Krona (SEK) 🇸🇪"+
                                "<br>- Singapore Dollar (SGD) 🇸🇬"+
                                "<br><br> We also accept deposits via <span style=\"color:#FFF\">credit card</span>. " +
                                "<br>To use this payment method, it is necessary to register through the Mt Pelerin widget." +
                                "If you have any questions or need assistance, do not hesitate to contact our team.",
                        },
                        2: {
                            title: "Fees",
                            text: "At BlockPilot, we offer free transfers for each deposit made within a 24-hour period that does not exceed €500." +
                                "<br> No fees will be applied for these deposits."+
                                "For deposits exceeding €500 within a 24-hour period, our partner Mt Pelerin applies the following fees according to their pricing grid:"+
                                "<br>From €500 to €4,999: 1.3%" +
                                "<br>From €5,000 to €49,999: 1.1%" +
                                "<br>From €50,000 to €100,000: 0.9%" +
                                "<br><br>Please note that your bank and its intermediaries may also apply fees for international payments.",
                        },
                        3: {
                            title: "Delay",
                            text: "Please note that if it is your first deposit via our partner Mt Pelerin, it will take approximately 2 hours to complete " +
                                "the Mt Pelerin/BlockPilot account verification. " +
                                "<br>Once this verification is completed, please allow <span style=\"color:#FFF\">7 business days</span> for the funds to be available in your BlockPilot account." +
                                "<br>For subsequent deposits, funds may take <span style=\"color:#FFF\">1 to 5 business days</span> to appear in your BlockPilot account.",
                        },
                        4: {
                            title: "Questions",
                            text: "If you have any questions about the transfer or encounter any issues during your deposit, " +
                                "<br>please do not hesitate to contact our customer service." +
                                "<br>We are here to assist you.",
                        },
                    },
                    cryptoAgreements: {
                        1: {
                            title: "Crypto Policy",
                            text: "The minimum amount for a deposit is equivalent to $200,\n" +
                                "while the maximum amount is $50,000.<br> \n" +
                                "\n" +
                                "For any transaction exceeding this amount, please contact us directly. \n"+
                                " <br><br> \n" +
                                "The blockchain address to use is <span style=\"color:#FFF\">0xA33335CC3353516b31aED3edC171C3Bb1ceD6E12.</span>\n"+
                                "<br><br>Additionally, we now support ENS addresses, ours being blockp.bnb.\n" +
                                "<br><br>Please note that the Block Pilot protocol currently only accepts currencies from the BEP20 network. \n " +
                                "If you use the wrong network when making your blockchain transfer, \n" +
                                "your funds may not necessarily be lost, but it could result in additional fees.\n" +
                                "For a precise description of the process, do not hesitate to consult our documentation. \n"
                            ,
                        },
                        2: {
                            title: "Fees",
                            text:  "Please note that BlockPilot is not responsible for any potential transfer fees " +
                                "that may be charged by your blockchain service provider during" +
                                " the transfer operation. These fees are typically determined by the network itself.",
                        },
                        3: {
                            title: "Delay",
                            text: "After the transfer, please note that funds may take between <span style=\"color:#FFF\">1 to 5 business days</span> to be " +
                                "credited to your account due to the processing times of the blockchain and our internal systems.",
                        },
                        4: {
                            title: "Questions",
                            text: "If you have any questions about the transfer or encounter any issues during your deposit, " +
                                "<br>please do not hesitate to contact our customer service." +
                                "<br>We are here to assist you.",
                        },
                    },
                },
                step3: {
                    title: "Depot amount",
                    description: "Read user agreement",
                    amount: {
                        label: "Enter amount",
                        tooltip: "Enter deposit amount, min 200",
                    },
                    currency: {
                        label: "Now available only EUR",
                    },
                    tokenSymbol: {
                        label: "Crypto symbol",
                        tooltip: "The abbreviated name of a cryptocurrency's coin or token. Like BUSD, ADA, NEAR",
                    },
                },
                step4: {
                    title: "Confirmation",
                    description: "Confirmation of depot",
                    bankTransfer: "Bank transfer",
                    cryptoTransfer: "Crypto transfer",
                    notice: "We reserve the right to refuse a deposit or to return all or a portion of the deposited amount." +
                        " Please note that all accepted deposits are subject to subsequent verification to ensure compliance " +
                        "with our rules and regulations." +
                        "<br> <br><span style=\"color:#FFF\"> The deposited amount will be visible in your BlockPilot account once this " +
                        "verification has been completed.</span>",
                    mtpelerin:"<span style=\"color:#FFF;font-weight:bold\">STEP 1:</span> Amount and Currency" +
                        "Select the amount and currency for your deposit." +
                        "<br> We accept various FIAT currencies, including Euro (EUR), US Dollar (USD), British Pound (GBP), etc."+
                        "<br><br><span style=\"color:#FFF;font-weight:bold\">STEP 2:</span> Choose Your Preferred Method" +
                        "Choose between a bank transfer or a credit card. " +
                        "<br> <br> <span style=\"color:#FFF;font-weight:bold\">STEP 3:</span> Connect Our Deposit Wallet " +
                        "To proceed, please connect our deposit wallet. " +
                        "<br> <br> <span style=\"color:#FFF;font-weight:bold\">STEP 4:</span> Enter the Destination Address:<br> " +
                        "<span style=\"color:#FFF\">0xA33335CC3353516b31aED3edC171C3Bb1ceD6E12 </span>" +
                        "<br><br>Congratulations! Your account has been successfully verified by our Swiss partner. You can now proceed with your deposit. " +
                        "<br><br><span style=\"color:#FFF\">Important Information:</span> Your Swiss bank details are now displayed. Select the IBAN, beneficiary, " +
                        "and make sure to pay special attention to the reference. The reference must be included in the transfer. If omitted, please contact our support. " +
                        "<br><br><span style=\"color:#FFF\">Important Information:</span> You can store the IBAN and beneficiary details by clicking on \"Share Bank Transfer Info\"." +
                        "<br><br>" +
                        "<span style=\"color:#FFF\">Very Important Information:</span> As per our terms and conditions, the first deposit may take up to <span style=\"color:#FFF\">7 business days to arrive.</span>" +
                        "If you have already made a bank deposit, it should take between <span style=\"color:#FFF\">1 and 2 business days.</span>",
                    txs:"Transaction Hash"
                },
            },
            accountInfo: {
                title: "Profile Details",
                label: {
                        fName: "Full Name",
                        username: "Username",
                        email: "Email",
                        phoneNumber: "Phone number",
                        postalAddress: "Postal address",
                        bio: "BIO",
                        avatar: "Avatar",
                },
                tooltip: {
                    changeAvatar: "Change avatar",
                    removeAvatar: "Remove avatar",
                    phoneNumber: "Enter international phone number like +12124567890",
                },
                placeholder: {
                    phoneNumber: "International phone number",
                }
            },

        },
        wallet_info: {
            netWorth: "Net Worth",
            benefit: "Benefit",
            potentialMonthlySalary: "Potential Monthly Salary",
            apy: "APY",
        },
        message: {
            notes: "Notes:",
            notice: "Notice",
            attention: "We need your attention!",
            warning: "WARNING! We need your attention!",
        },
        table: {
            id: "ID",
            depotUID: "Depot UID",
            status: "Status",
            amount: "Amount",
            date: "Date",
        },
        status: {
            alphaSM: "ALPHA",
            alphaLG: "ALPHA USER",
        },
        auth: {
            signIn: "Sign in",
            createAcc: "Create Account",
        },
        Swal: {
            submitted: "All is cool! Form has been successfully submitted",
            error: "Sorry, looks like there are some errors detected, please try again.",
            buttonOK: "Ok, got it!",
            buttonError: "Try again!",

        },
        validation: {
            requiredField: "This is a required field",
            onlyDigits: "Please use only digits",
            onlyLetters: "Please use only letters",
            minAmount: "200 is MIN",
            min: "must be at least 3 characters",
            max: "must be at most 6 characters",
            phoneNumber: "Enter international phone number like +12124567890",
        },
        general: {
            notAvailableToday: "Not available today",
            nextInvoice: "Next Invoice",
            comingSoon: "Coming soon.",
            noRecords: "No records found yet!",
            lastPosition: "Last Position",
            allowedFileTypes: "Allowed file types:",
            loading: "Loading...",
            clickHere: "click here",
        },
        error: {
            error404: "We can't find that page.",
            error500: "Something went wrong! Please try again later.",
            oops: "Oops!",
            sysError: "System Error",
        },
    },
    fr: {
        action: {
            save: "Enregistrer",
            saveChanges: "Enregistrer les changements",
            back: "Retour",
            submit: "Valider",
            continue: "Continuer",
            discard: "Abandonner",
            getSupport: "Aide",
            pleaseWait: "Merci de patienter...",
            increaseByDeposit: "Faire un dépôt",
            addPayment: "Ajouter un dépôt",
            editProfile: "Editer mon profil",
            returnHome: "Home"
        },
        boolean: {},
        themeMode: {
            light: "Lumineux",
            dark: "Sombre",
            system: "Système",
        },
        page: {
            dashboard: "Dashboard",
            sally: "Dynamic", //toDo
            salomon: "Balanced", //toDo
            silver: "Silver", //toDo
            depot: "DEPOSIT/WITHDRAW", //toDo
            documents: "Documents", //toDo

            account: "Mon compte",
            accountInfo: "Info",
            accountSettings: "Paramètres",
            signOut: "Déconnexion",
            signIn: "S'identifier",
        },
        section: {
            verification: {
                title: "Validation du compte",
                pending: "Votre compte est en cours de validation. Vous recevrez un email très prochainement pour finaliser l'inscription.<br/> Si vous avez des questions",
                refused: "Votre compte est actuellement bloqué, vous pouvez contacter un administrateur",
            },
            help: {
                label: "Aide",
                title: "Documentation",
                support: {
                    title: "Demander de l'aide",
                    description: "Rejoignez notre communauté pour trouver les réponses à vos questions\n" +
                        "                et aide les autres membres.",
                },
                docAndVideo: {
                    title: "Documentation & Vidéos",
                    description: "vidéos, guides et des exemples pour commencer",
                },
            },
            userNetworth: {
                total: {
                    title: "Evolution de votre capital net total",
                    description: "",
                },
                sally: {
                    title: "Evolution de votre capital Sally",
                    description: "",
                },
                salomon: {
                    title: "Evolution de votre capital Salomon",
                    description: "",
                },
                silver: {
                    title: "Evolution de votre capital Silver",
                    description: "",
                },
            },
            userBenefice: {
                total: {
                    title: "Evolution des Bénefices totaux",
                    description: "",
                },
                sally: {
                    title: "Evolution des bénefices sally",
                    description: "",
                },
                salomon: {
                    title: "Evolution des bénefices salomon",
                    description: "",
                },
                silver: {
                    title: "Evolution des bénefices silver",
                    description: "",
                },
            },
             bitcoinWidget: {
                title: "USD / BITCOIN Valeur 30 jours",
                description: "",
            },
            userVaultInvests: {
                title: "Arbitrage",
                description: "",
                mgmt:"Frais de gestion",
                referalBonus:"Bonus referal",
                withdrawDescription1: "Vous avez fait un retrait de",
                withdrawDescription2: "Valeur en USD au moment du retrait:",
                depositDescription1: "Vous avez fait un dépôt de",
                depositDescription2: "Valeur en USD au moment du dépôt:",
            },
            depositList: {
                title: "Deposit",
                description: "Ci-dessous, la liste de tous vos dépôts.\n" +
                    "          Si vous ne voyez pas un dépôt effectué, pas de panique, il n'est pas encore validé.\n",
                depositDescription: "Vous avez fait un dépôt de",
            },
            vaultPie: {
                title: "Répartition",
                vault1: "Sally",
                vault2: "Salomon Exch.",
                vault3: "Silver 3",
            },
            documentList: {
                title: "Documents",
                description: "Télécharger",
            },
            userVault: {
                title: "Portfolio",
                description: "Votre pourcentage change tous les jours en fonction de la valeur net des portefeuilles",
                note: "",
            },
            depositTable: {
                title: "List des dépôts",
            },
            depositModal: {
                title: "Nouveau dépôt",
                step1: {
                    title: "Type de dépôt",
                    description: "Transfert IBAN ou Crypto",
                    tooltip: "Sélectionner l'une des méthodes de dépôt.",
                    bank: {
                        title: "Virement SEPA",
                        description: "Virement SEPA vers l'IBAN BlockPilot",
                    },
                    crypto: {
                        title: "Transfert d'actif Crypto",
                        description: "Transfert d'actif crypto vers le wallet BlockPilot.",
                    },
                },
                step2: {
                    title: "Conditions",
                    description: "Choisissez le montant",
                    tooltip: "J'accepte les conditions",
                    agree: "J'accepte les conditions",
                    bankAgreements: {
                        1: {
                            title: "Règlement du dépôt",
                            text: "The transfer must come from a\n" +
                                "bank account in your name\n" +
                                "Transfers from an account that is\n" +
                                " not in your name will be rejected.,\n" +
                                "\n\n" +
                                "Only SEPA and SWIFT transfers\n" +
                                "are accepted\n" +
                                "\n\n" +
                                "Only Euro € is accepted\n" +
                                "Minimum amount 200 € / transfer Amount\n" +
                                "maximum €50,000 / transfer\n\n\n",

                        },
                        2: {
                            title: "Frais",
                            text: "Nos frais de gestion sont de O,15% du montant transféré\n" +
                                "Votre banque et ses intermédiaires peuvent\n" +
                                "appliquer des frais pour des paiments\n" +
                                "internationals.",
                        },
                        3: {
                            title: "Délai",
                            text: "Après le virement, les fonds peuvent prendre\n" +
                                " jusqu'à 1 à 5 jours ouvrables à s'afficher sur votre interface.",
                        },
                        4: {
                            title: "Questions",
                            text: "Si vous des questions concernant le\n" +
                                "virement bancaire, contactez notre service\n" +
                                "client.",
                        },
                    },
                    cryptoAgreements: {
                        1: {
                            title: "Règlement du dépôt",
                            text: "Montant minimum équivalent 200 $ \n" +
                                "maximum 50.000 $ \n\n\n" +
                                "L'adresse est 0xaB2e4E7b0CB59236bB38dBa45Aa45A0d94D683D0\n\n"+
                                "Le protocole Block Pilot n'accepte que les monnaies \n" +
                                " du réseau BEP20 UNIQUEMENT pour le moment. \n" +
                                " Ne vous trompez pas de réseau lors de votre virement blockchain. \n" +
                                " Vos fonds peuvent être perdus. \n" +
                                " Pour une description précise du process\n" +
                                " vous pouvez vous référez à la documentations. ",

                        },
                        2: {
                            title: "Frais",
                            text: "BlockPilot n'est pas responsable des frais de transfert \n" +
                                "qui peuvent être appliqués.",
                        },
                        3: {
                            title: "Délai",
                            text: "Après le virement, les fonds peuvent prendre\n" +
                                " jusqu'à 1 à 5 jours ouvrables à s'afficher sur votre interface.",
                        },
                        4: {
                            title: "Questions",
                            text: "Si vous des questions concernant le\n" +
                                "transfert, contactez notre service\n" +
                                "client disponible 24h/24 et 71/7.",
                        },
                    },
                },
                step3: {
                    title: "Montant du transfert",
                    description: "",
                    amount: {
                        label: "Montant",
                        tooltip: "Renseignez le montant",
                    },
                    currency: {
                        label: "Devise",
                    },
                    tokenSymbol: {
                        label: "Token Symbole",
                        tooltip: "Exemples: BUSD, ADA, NEAR",
                    },
                },
                step4: {
                    title: "Confirmation",
                    description: "Confirmation du dépôt",
                    bankTransfer: "Virement SEPA",
                    cryptoTransfer: "Transfert Crypto ",
                    notice: "Nous pouvons refuser votre dépôt s'il ne correspond pas à votre déclaration.\n" +
                        " Dans ce cas, il vous sera retourner.\n" +
                        " Les frais de retour seront à votre charge.\n" +
                        " ",

                },
            },
            accountInfo: {
                title: "Profile",
                label: {
                    fName: "Nom Complet",
                    username: "Identifiant",
                    email: "Email",
                    phoneNumber: "Numéro de téléphone",
                    postalAddress: "Adresse postale",
                    bio: "BIO",
                    avatar: "Photo de profil",
                },
                tooltip: {
                    changeAvatar: "Modifier sa photo de profil",
                    removeAvatar: "Supprimer sa photo de profil",
                    phoneNumber: "Renseignez un numéro international, exemple +12124567890",
                },
                placeholder: {
                    phoneNumber: "Numéro de téléphone international",
                }
            },

        },
        wallet_info: {
            netWorth: "Valeur Net",
            benefit: "Bénéfice",
            potentialMonthlySalary: "Revenu mensuel potentiel",
            apy: "APY",
        },
        message: {
            notes: "Notes:",
            notice: "Notice",
            attention: "Nous avons besoin de votre attention!",
            warning: "Attention! Nous avons besoin de votre attention!",
        },
        table: {
            id: "ID",
            depotUID: "Depot UID",
            status: "Status",
            amount: "Amount",
            date: "Date",
        },
        status: {
            alphaSM: "ALPHA",
            alphaLG: "ALPHA USER",
        },
        auth: {
            signIn: "Se connecter",
            createAcc: "Créer un acompte",
        },
        Swal: {
            submitted: "Tout est ok, nous avons bien reçu votre demande",
            error: "Il semble qu'il y ait une erreur, pouvez vous réessayer",
            buttonOK: "Ok",
            buttonError: "Réessayer!",

        },
        validation: {
            requiredField: "Ce champs est obligatoire",
            onlyDigits: "Utilisez seulement des chiffres",
            onlyLetters: "Utilisez seulement des lettres",
            minAmount: "200 est la valeur minimum",
            min: "Min 4 caractères",
            max: "Max 5 caractères",
            phoneNumber: "Renseignez un numéro international, exemple +12124567890",
        },
        general: {
            notAvailableToday: "Non disponible auujourd'hui",
            nextInvoice: "Prochaine facture",
            comingSoon: "Bientôt disponible",
            noRecords: "Aucuns enregistrements trouvés!",
            lastPosition: "Dernière position",
            allowedFileTypes: "Type de fichier autorisé:",
            loading: "Chargement...",
            clickHere: "cliquez ici",
        },
        error: {
            error404: "Nous ne trouvons la page demandée.",
            error500: "Une erreur s'est produite! Essayez plus tard.",
            oops: "Oops!",
            sysError: "Erreur sytème",
        },
    },
};

const locales = createI18n({
    legacy: false,
    locale: "en",
    globalInjection: true,
    messages,
});

export default locales;
