import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface EvoWatchNetworthTotal {
  wallet_value: string;
}

export interface EvoWatchNetworthTotalSet {
  errors: unknown;
  EvoWatchNetworthTotal: EvoWatchNetworthTotal;
}

@Module
export default class NetWorthEvolutionTotal extends VuexModule implements EvoWatchNetworthTotalSet {
  errors = {};
  EvoWatchNetworthTotal = {} as EvoWatchNetworthTotal;

  /**
   * Get current Wallet object
   * @returns EvoWatchNetworthTotal
   */
  get getNetworthEvoTotal(): EvoWatchNetworthTotal {
    return this.EvoWatchNetworthTotal;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getEvoTotalErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_VAULT_NETWORTH_EVO_TOTAL](data) {
    this.EvoWatchNetworthTotal = data;
    this.errors = {};
  }

  @Action
  [Actions.GET_VAULT_NETWORTH_EVO_TOTAL]() {
    ApiService.setHeader();
    ApiService.get("/get-vault-networth-evo-total")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_VAULT_NETWORTH_EVO_TOTAL, data);
      })
      .catch(({ response }) => {
        //alert('error get wallet list API is broken');
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}

