import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Deposits {
    id: number;
    status: number;
    type: number;
    currency: string;
    amount: string;
    token_symbol: string;
    validation_option: boolean;
    create_datetime: string;
    update_datetime: string;
}

export interface DepositsTypes {
    id: number;
    name: string;
}

export interface DepositsStatuses {
    id: number;
    name: string;
}

export interface DepositsInfo {
    errors: unknown;
    deposits: Deposits;
    types: DepositsTypes;
    statuses: DepositsStatuses;
}

@Module
export default class DsafeDepositsModule extends VuexModule implements DepositsInfo {
    errors = {};
    deposits = {} as Deposits;
    types = {} as DepositsTypes;
    statuses = {} as DepositsStatuses;

    /**
     * Get current user object
     * @returns DepositsInfo
     */
    get currentUserDeposits(): Deposits {
        return this.deposits;
    }

    /**
     * Get current user object
     * @returns DepositsTypes
     */
    get depositsTypes(): DepositsTypes {
        return this.types;
    }

    /**
     * Get current user object
     * @returns DepositsStatuses
     */
    get depositsStatuses(): DepositsStatuses {
        return this.statuses;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getDepositsErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_DEPOSITS](data) {
        this.deposits = data;
        this.errors = {};
    }

    @Mutation
    [Mutations.SET_DEPOSITS_TYPES](data) {
        this.types = data;
        this.errors = {};
    }

    @Mutation
    [Mutations.SET_DEPOSITS_STATUSES](data) {
        this.statuses = data;
        this.errors = {};
    }

    @Action
    [Actions.GET_DEPOSITS]() {
        ApiService.setHeader();
        ApiService.get("/deposits")
            .then(({ data }) => {
                /*console.log("data", data.data)*/
                this.context.commit(Mutations.SET_DEPOSITS, data);
            })
            .catch(({ response }) => {
                /*console.log("response", response)*/
                //alert("error get Deposits list API is broken");
                this.context.commit(Mutations.SET_ERROR, response.error);
            });
    }

    @Action
    [Actions.GET_DEPOSITS_TYPES](slug) {
        ApiService.setHeader();
        ApiService.get("/deposit-types",slug)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_DEPOSITS_TYPES, data);
            })
            .catch(({ response }) => {
                //alert("error get Deposits list API is broken");
                this.context.commit(Mutations.SET_ERROR, response.error);
            });
    }

    @Action
    [Actions.GET_DEPOSITS_STATUSES](slug) {
        ApiService.setHeader();
        ApiService.get("/deposit-statuses",slug)
            .then(({ data }) => {

                this.context.commit(Mutations.SET_DEPOSITS_STATUSES, data);
            })
            .catch(({ response }) => {

                //alert("error get Deposits list API is broken");
                this.context.commit(Mutations.SET_ERROR, response.error);
            });
    }

    @Action
    [Actions.POST_DEPOSITS](payload) {
        /*console.log("payload", payload)*/
        ApiService.setHeader();
        ApiService.post("/deposits/", payload)
            .then(({ data }) => {

                const user = data.data;
                this.context.commit(Mutations.SET_USER, user);
            })
            .catch(({ response }) => {

                this.context.commit(Mutations.SET_ERROR, response.error);
            });
    }
    @Action
    [Actions.POST_VERIFICATION](payload) {
        /*console.log("payload", payload)*/
        ApiService.setHeader();
        ApiService.post("/verification/", payload)
            .then(({ data }) => {

                const user = data.data;
                this.context.commit(Mutations.SET_USER, user);
            })
            .catch(({ response }) => {

                this.context.commit(Mutations.SET_ERROR, response.error);
            });
    }
    @Action
    [Actions.POST_ONBOARDING](payload) {
        /*console.log("payload", payload)*/
        ApiService.setHeader();
        ApiService.post("/onboarding/", payload)
            .then(({ data }) => {

                const user = data.data;
                this.context.commit(Mutations.SET_USER, user);
            })
            .catch(({ response }) => {

                this.context.commit(Mutations.SET_ERROR, response.error);
            });
    }
}
