import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface UserDsafeWalletInfo {
  user_percentage_in_vault: string;
}

export interface UserDsafeWalletInfoInfo {
  errors: unknown;
  UserDsafeWalletInfo: UserDsafeWalletInfo;
}

@Module
export default class DsafeWalletUserInfoModule extends VuexModule implements UserDsafeWalletInfoInfo {
  errors = {};
  UserDsafeWalletInfo = {} as UserDsafeWalletInfo;

  /**
   * Get current user objecte
   * @returns UserDsafeWalletInfo
   */
  get currentWalletUserInfo(): UserDsafeWalletInfo {
    return this.UserDsafeWalletInfo;
  }


  /**
   * Get authentification errors
   * @returns array
   */
  get getUserStatsErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_USER_DSAFE_WALLET_INFO](data) {
    this.UserDsafeWalletInfo = data;
    this.errors = {};
  }


  @Action
  [Actions.GET_USER_DSAFE_WALLET_INFO]() {
    ApiService.setHeader();
    ApiService.get("/get-stat-dsafe-wallet-info")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER_DSAFE_WALLET_INFO, data);
       })
      .catch(({ response }) => {
        //alert("error get deposit list API is broken");
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}

