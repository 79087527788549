import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface UserInvest {
    wallet_value: string;
}

export interface UserInvestSet {
    errors: unknown;
    userInvest: UserInvest;
}

@Module
export default class UserInvestProfil extends VuexModule implements UserInvestSet {
    errors = {};
    userInvest = {} as UserInvest;

    get getUserInvest(): UserInvest {
        return this.userInvest;
    }

    /**
     * Get authentification errors
     * @returns array UserInvest
     */
    get getUserInvestErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_USER_INVEST_PROFIL](data) {
        this.userInvest = data;
        this.errors = {};
    }

    @Action
    [Actions.GET_USER_INVEST_PROFIL]() {
        ApiService.setHeader();
        ApiService.get("/get-user-invest-profil")
            .then(({ data }) => {
                this.context.commit(Mutations.SET_USER_INVEST_PROFIL, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data);
            });
    }
}

