const ID_TOKEN_KEY = "id_token" as string;
const USER_INFO_KEY = "userInfo" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

/**
 * @description get userInfo form localStorage
 */
export const getUserInfo = (): string | null => {
  return window.localStorage.getItem(USER_INFO_KEY);
};

/**
 * @description save userInfo into localStorage
 * @param user: string
 */
export const saveUserInfo = (user: string): void => {
  window.localStorage.setItem(USER_INFO_KEY, user);
};

/**
 * @description remove userInfo form localStorage
 */
export const destroyUserInfo = (): void => {
  window.localStorage.removeItem(USER_INFO_KEY);
};


export default { getToken, saveToken, destroyToken, getUserInfo, saveUserInfo, destroyUserInfo };
