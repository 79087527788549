import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface RefererIncomes {
  email: string;
}

export interface RefererIncomesSet {
  errors: unknown;
  refererIncomes: RefererIncomes;
}

@Module
export default class DsafeRefererIncomesModule extends VuexModule implements RefererIncomesSet {
  errors = {};
  refererIncomes = {} as RefererIncomes;

  get currentUserRefererIncomes(): RefererIncomes {
    //console.log(this.refererIncomes)
    return this.refererIncomes;
  }

  get getRecommandeeErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_REFERER_INCOMES](data) {
    this.refererIncomes = data;
    this.errors = {};
  }

  @Action
  [Actions.GET_REFERER_INCOMES]() {
    ApiService.setHeader();
    ApiService.get("/get-user-referer-incomes")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_REFERER_INCOMES, data);
       })
      .catch(({ response }) => {
        //alert("error get refererIncomes list API is broken");
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }



}

