import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  sub: string;
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  bio: string;
  phone_number: string;
  postal_address: string;
  block_pilot_user_status: string;
}

export interface AuthError {
  detail: string;
}

export interface UserAuthInfo {
  error: AuthError;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  error = {} as AuthError;
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    this.user.updated_at = "I can read this ! Get currentUser got the user";
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Verify user authentication
   * @returns AuthError
   */
  get myget(): string {
    return "my get function";
  }

  /**
   * Get authentification errors
   * @returns AuthError
   */
  get getAuthErrors(): string {
    console.log("2" + this.error.detail);
    return this.error.detail;
  }

  @Mutation
  [Mutations.SET_ERROR](response) {
    this.error = response;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.error = {} as AuthError;
    JwtService.saveToken(user.access_token);
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.error = {} as AuthError;
    JwtService.destroyToken();
    JwtService.destroyUserInfo();
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = Object.assign(this.user, user);
    JwtService.saveUserInfo(JSON.stringify(this.user));
  }

  @Action
  [Actions.UPDATE_USER](credentials) {
    if (credentials) {
      this.context.commit(Mutations.SET_USER, credentials);
    }
  }

  @Action
  [Actions.GET_USER]() {
    ApiService.setHeader();
    ApiService.get("/users/me")
        .then(({ data }) => {
          const user = data.data;
          this.context.commit(Mutations.SET_USER, user);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.error);
        });
  }

  @Action
  [Actions.PATCH_USER](payload) {
    delete payload.picture;
    ApiService.setHeader();
    ApiService.patch("/users/me", payload)
        .then(({ data }) => {
          const user = data.data;

          this.context.commit(Mutations.SET_USER, user);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.error);
        });
  }

  @Action
  [Actions.LOGIN](credentials) {
    if (credentials) {
      this.context.commit(Mutations.SET_AUTH, {access_token:  credentials.access_token});
    }
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (this.isAuthenticated) {
      // get User inf
      ApiService.setHeader();
      ApiService.get("/users/me")
        .then()
        .catch(() => {
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
