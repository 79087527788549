import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface UserNetWorthGlobal {
    wallet_value: string;
}

export interface UserNetWorthGlobalSet {
    errors: unknown;
    userNetWorthGlobal: UserNetWorthGlobal;
}

@Module
export default class NetWorthGlobal extends VuexModule implements UserNetWorthGlobalSet {
    errors = {};
    userNetWorthGlobal = {} as UserNetWorthGlobal;

    get getNetWorthGlobal(): UserNetWorthGlobal {
        return this.userNetWorthGlobal;
    }

    /**
     * Get authentification errors
     * @returns array userNetWorthGlobal
     */
    get getNetWorthGlobalErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_USER_NETWORTH_GLOBAL](data) {
        this.userNetWorthGlobal = data;
        this.errors = {};
    }

    @Action
    [Actions.GET_USER_NETWORTH_GLOBAL]() {
        ApiService.setHeader();
        ApiService.get("/get-user-networth/global")
            .then(({ data }) => {
                this.context.commit(Mutations.SET_USER_NETWORTH_GLOBAL, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data);
            });
    }
}

