import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface EvoWatchBenefitUser {
  wallet_value: string;
}

export interface EvoWatchBenefitUserSet {
  errors: unknown;
  EvoWatchBenefitUser: EvoWatchBenefitUser;
}

@Module
export class BenefitEvolutionUserDynamic extends VuexModule implements EvoWatchBenefitUserSet {
  errors = {};
  EvoWatchBenefitUser = {} as EvoWatchBenefitUser;

  /**
   * Get current Wallet object
   * @returns EvoWatchBenefitUserSet
   */
  get getEvoBenefitUserDynamic(): EvoWatchBenefitUser {
    return this.EvoWatchBenefitUser;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getBenefErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_VAULT_BENEFICE_EVO_USER_DYNAMIC](data) {
    this.EvoWatchBenefitUser = data;
    this.errors = {};
  }

 
  @Action
  [Actions.GET_USER_NETWORTH_EVO_DYNAMIC](id) {
    ApiService.setHeader();
    ApiService.get("/get-vault-benefice-evo-user", id)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_VAULT_BENEFICE_EVO_USER_DYNAMIC, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
}

@Module
export class BenefitEvolutionUserBalanced  extends VuexModule implements EvoWatchBenefitUserSet {
  errors = {};
  EvoWatchBenefitUser = {} as EvoWatchBenefitUser;

  /**
   * Get current Wallet object
   * @returns EvoWatchBenefitUserSet
   */
  get getEvoBenefitUserBalanced(): EvoWatchBenefitUser {
    return this.EvoWatchBenefitUser;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getBenefErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_VAULT_BENEFICE_EVO_USER_BALANCED](data) {
    this.EvoWatchBenefitUser = data;
    this.errors = {};
  }

  @Action
  [Actions.GET_VAULT_BENEFICE_EVO_USER_BALANCED](id) { 
    ApiService.setHeader();
    ApiService.get("/get-vault-benefice-evo-user", id)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_VAULT_BENEFICE_EVO_USER_BALANCED, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
}


