import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface UserBeneficeGlobal {
    wallet_value: string;
}

export interface UserBeneficeGlobalSet {
    errors: unknown;
    userBeneficeGlobal: UserBeneficeGlobal;
}

@Module
export default class BeneficeGlobal extends VuexModule implements UserBeneficeGlobalSet {
    errors = {};
    userBeneficeGlobal = {} as UserBeneficeGlobal;

    get getUserBeneficeGlobal(): UserBeneficeGlobal {
        return this.userBeneficeGlobal;
    }

    /**
     * Get authentification errors
     * @returns array userBeneficeGlobal
     */
    get getUserBeneficeGlobalErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_USER_BENEFICE_GLOBAL](data) {
        this.userBeneficeGlobal = data;
        this.errors = {};
    }

    @Action
    [Actions.GET_USER_BENEFICE_GLOBAL]() {
        ApiService.setHeader();
        ApiService.get("/get-user-benefice/global")
            .then(({ data }) => {
                this.context.commit(Mutations.SET_USER_BENEFICE_GLOBAL, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data);
            });
    }
}

