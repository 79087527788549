import moment from "moment";

const filters = {
  format_date(strValue) {
    if (!strValue) {
      return "-";
    }
    return moment(String(strValue)).format('DD/MM/YYYY');
  },
  format_crypto_fiat(toFixed){
    if (!toFixed) {
      return "-";
    }
    return toFixed.toFixed(2);
  },
  format_long_to_short(formatedValue){
    if (!formatedValue) {
      return " ";
    } else {
      /*if (Math.floor(+formatedValue).toString().length < 17) {
        return formatedValue;
      }*/
      return (formatedValue / 1000000000000000000).toLocaleString('en-US', {useGrouping:false, maximumSignificantDigits:20});
    }
  }
}
export default filters;
