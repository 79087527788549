import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface UserYield {

    data: string;
}

export interface UserYieldSet {
    errors: unknown;
    userYield: UserYield;
}

@Module
export default class UserYieldModule extends VuexModule implements UserYieldSet {
    errors = {};
    userYield = {} as UserYield;

    get getUserYieldSet(): UserYield {
        return this.userYield;
    }

    get getVaultInvestsErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_USER_YIELD](data) {
        this.userYield = data;
        this.errors = {};
    }

    @Action
    [Actions.GET_USER_YIELD](id) {

        ApiService.setHeader();
        ApiService.get("/get-user-transfers", id)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_USER_YIELD, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data);
            });
    }

}

