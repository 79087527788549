import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Activity, ActivityInfo } from "@/store/modules/DsafeActivity";

export interface Document {
  id: number;
  description: string;
  user: number;
  type: number;
  file: string;
  update_datetime: string;
  create_datetime: string;
}

export interface DocumentInfo {
  errors: unknown;
  document: Document;
}

@Module
export default class DocumentsListModule
  extends VuexModule
  implements DocumentInfo
{
  errors = {};
  document = {} as Document;

  /**
   * Get current user documents list
   * @returns DocumentInfo
   */
  get currentDocument(): Document {
    return this.document;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getDocumentErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_DOCUMENTLIST](data) {
    this.document = data;
    this.errors = {};
  }

  @Action
  [Actions.GET_DOCUMENTLIST]() {
    ApiService.setHeader();
    ApiService.get("/documents")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DOCUMENTLIST, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
