import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface EvoWatchSalaryUser {
  wallet_value: string;
}

export interface EvoWatchSalaryUserSet {
  errors: unknown;
  EvoWatchSalaryUser: EvoWatchSalaryUser;
}

@Module
export default class EvoWatchSalaryUserClass extends VuexModule implements EvoWatchSalaryUserSet {
  errors = {};
  EvoWatchSalaryUser = {} as EvoWatchSalaryUser;

  /**
   * Get current Wallet object
   * @returns EvoWatchSalaryUserSet
   */
  get GetEvoWatchSalaryUser(): EvoWatchSalaryUser {
    return this.EvoWatchSalaryUser;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getMonthlySalaryEvoUserErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_VAULT_MONTHY_SALARY_EVO_USER](data) {
    this.EvoWatchSalaryUser = data;
    this.errors = {};
  }

  @Action
  [Actions.GET_VAULT_MONTHY_SALARY_EVO_USER]() {
    ApiService.setHeader();
    ApiService.get("/get-vault-benefice-evo-user")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_VAULT_MONTHY_SALARY_EVO_USER, data);
      })
      .catch(({ response }) => {
        //alert('error get wallet list API is broken');
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}

