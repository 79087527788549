import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface UserVaultInvestsSummary {

    value: string;
    create_datetime: string;
    gas: string;
    gasPrice: string;
}

export interface UserVaultInvestsSummarySet {
    errors: unknown;
    userVaultInvestsSummary: UserVaultInvestsSummary;
}

@Module
export default class UserVaultInvestsSummaryModule extends VuexModule implements UserVaultInvestsSummarySet {
    errors = {};
    userVaultInvestsSummary = {} as UserVaultInvestsSummary;

    get getUserVaultInvestsSummarySet(): UserVaultInvestsSummary {
        return this.userVaultInvestsSummary;
    }

    get getVaultInvestsErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_USER_VAULT_INVESTS_SUMMARY](data) {
        this.userVaultInvestsSummary = data;
        this.errors = {};
    }

    @Action
    [Actions.GET_USER_VAULT_INVESTS_SUMMARY](id) {

        ApiService.setHeader();
        ApiService.get("/get-user-invests-summary", id)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_USER_VAULT_INVESTS_SUMMARY, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data);
            });
    }

}

