import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Activity {
  value: string;
  create_datetime: string;
  gas: string;
  gasPrice: string;
}

export interface ActivityInfo {
  errors: unknown;
  activity: Activity;
}

@Module
export default class DsafeActivityModule extends VuexModule implements ActivityInfo {
  errors = {};
  activity = {} as Activity;

  /**
   * Get current user objecte
   * @returns ActivityInfo
   */
  get currentUserDsActivityList(): Activity {
    return this.activity;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getActivityErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_DSACTIVITYLIST](data) {
    this.activity = data;
    this.errors = {};
  }

  @Action
  [Actions.GET_DSACTIVITYLIST]() {
    ApiService.setHeader();
    ApiService.get("/get-dsactivity-list")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DSACTIVITYLIST, data);
       })
      .catch(({ response }) => {
        //alert("error get Activity list API is broken");
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

}
