import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface VaultExpo {

    data: string;
}

export interface VaultExpoSet {
    errors: unknown;
    vaultExpo: VaultExpo;
}

@Module
export default class VaultExpoModule extends VuexModule implements VaultExpoSet {
    errors = {};
    vaultExpo = {} as VaultExpo;

    get getVaultExpoSet(): VaultExpo {
        return this.vaultExpo;
    }

    get getVaultInvestsErrors() {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }

    @Mutation
    [Mutations.SET_VAULT_EXPO](data) {
        this.vaultExpo = data;
        this.errors = {};
    }

    @Action
    [Actions.GET_VAULT_EXPO](id) {

        ApiService.setHeader();
        ApiService.get("/get-vaults-exposure", id)
            .then(({ data }) => {
                this.context.commit(Mutations.SET_VAULT_EXPO, data);
            })
            .catch(({ response }) => {
                this.context.commit(Mutations.SET_ERROR, response.data);
            });
    }

}

