import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import DsafeModule from "@/store/modules/DsafeModule";
import DsafeWalletModule from "@/store/modules/DsafeWalletModule";
import DsafeWalletUserInfoModule from "@/store/modules/DsafeWalletUserInfoModule";
import DsafeActivityModule from "@/store/modules/DsafeActivity";
import DsafeRecommandeeModule from "@/store/modules/DsafeRecommandee";
import DsafeWalletValueModule from "@/store/modules/NetWorthEvolutionTotal";
import {NetWorthEvolutionUserDynamic,NetWorthEvolutionUserBalanced} from "@/store/modules/UserNetWorthEvolution";
import NetWorthEvolutionTotal from "@/store/modules/NetWorthEvolutionTotal";
import {BenefitEvolutionUserDynamic,BenefitEvolutionUserBalanced} from "@/store/modules/UserBenefitEvolution";
import EvoWatchSalaryUserClass from "@/store/modules/MonthlySalaryEvolutionUser";
import EvoWatchPotentialSalaryUserClass from "@/store/modules/MonthlyPotentialSalaryEvolutionUser";
import DocumentsListModule from "@/store/modules/DocumentsListModule";
import DsafeDepositsModule from "@/store/modules/DsafeDepositsModule";
import UserNetWorthGlobal from "@/store/modules/UserNetWorthGlobal";
import UserBeneficeGlobal from "@/store/modules/UserBeneficeGlobal";
import UserVaultInvestsModule from "@/store/modules/UserVaultInvestsModule";
import UserVaultInvestsSummaryModule from "@/store/modules/UserVaultInvestsSummaryModule";
import UserVaultInvestsSummaryAllTimeModule from "@/store/modules/UserVaultInvestsSummaryAllTimeModule";
import UserInvestProfil from "@/store/modules/UserInvestProfil";
import VaultExpo from  "@/store/modules/VaultExposure2";
import UserYield from "@/store/modules/UserYield";
import DsafeRefererIncomes from "@/store/modules/DsafeRefererIncomes";

config.rawError = true;

const store = createStore({
  state:{
    access:'',
    helloMessage:"Hello from store"
  },
  modules: {
    AuthModule,
    BodyModule,
    ConfigModule,
    ThemeModeModule,
    BreadcrumbsModule,
    DsafeModule,
    DsafeWalletModule,
    DsafeWalletUserInfoModule,
    DsafeActivityModule,
    DsafeRecommandeeModule,
    DsafeWalletValueModule,
    NetWorthEvolutionUserDynamic,
    NetWorthEvolutionUserBalanced,
    NetWorthEvolutionTotal,
    BenefitEvolutionUserDynamic,
    BenefitEvolutionUserBalanced,
    EvoWatchSalaryUserClass,
    EvoWatchPotentialSalaryUserClass,
    DocumentsListModule,
    DsafeDepositsModule,
    UserNetWorthGlobal,
    UserBeneficeGlobal,
    UserVaultInvestsModule,
    UserVaultInvestsSummaryModule,
    UserVaultInvestsSummaryAllTimeModule,
    UserInvestProfil,
    VaultExpo,
    UserYield,
    DsafeRefererIncomes,

  },
});

export default store;
