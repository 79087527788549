import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import locales from "@/core/locales/locales";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
import filters from "@/core/helpers/filters";
import translate from "@/core/helpers/translate";
import { createAuth0 } from '@auth0/auth0-vue';

const app = createApp(App);

app.config.globalProperties.$filters = filters
app.config.globalProperties.$translate = translate

app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(locales);
app.use(
    createAuth0({
        domain: process.env.VUE_APP_AUTH_DOMAIN as string,
        client_id: process.env.VUE_APP_AUTH_CLIENT_ID as string,
        redirect_uri: window.location.origin,
        audience: process.env.VUE_APP_AUTH_AUDIENCE as string,
    })
);

app.mount("#app");
