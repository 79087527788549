import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Deposit {
  value: string;
  create_datetime: string;
  gas: string;
  gasPrice: string;
}

export interface DepositInfo {
  errors: unknown;
  deposit: Deposit;
}

@Module
export default class DepositModule extends VuexModule implements DepositInfo {
  errors = {};
  deposit = {} as Deposit;

  /**
   * Get current user objecte
   * @returns DepositInfo
   */
  get currentUserDepositList(): Deposit {
    return this.deposit;
  }


  /**
   * Get authentification errors
   * @returns array
   */
  get getDepositErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_DEPOSITLIST](data) {
    this.deposit = data;
    this.errors = {};
  }

  @Action
  [Actions.GET_DEPOSITLIST]() {
    ApiService.setHeader();
    ApiService.get("/get-deposit-list")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DEPOSITLIST, data);
       })
      .catch(({ response }) => {
        //alert("error get deposit list API is broken");
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

}

